import $ from "jquery";
export default class Dialog {
  constructor() {
    // Delete Button
    $('.delete-item').click( function(e) {
      e.preventDefault();
      $(this.parentElement.parentElement).toggleClass("open-delete")
    })
    
    $('.delete-cancel').click( function(e) {
      e.preventDefault();
      var currentElement = this;
      while(!$(currentElement).hasClass('open-delete') ) {
        currentElement = currentElement.parentElement
        if ($(currentElement).hasClass('open-delete')) {
          $(currentElement).toggleClass('open-delete')
        }
      }
    })
  
    $('button.final-delete-item').click( function() {
      $(this).parent().parent().slideToggle()
    })

    // Three Dots Button
    $('.btn-group-wrap .dots-wrap').click( function() {
      $(this.parentElement).toggleClass("open")
    })
    $('.btn-group-wrap .x').click( function() {
      $(this.parentElement.parentElement).toggleClass("open")
    })

    // Photo Delete Button

  }
}