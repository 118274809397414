import tinymce from 'tinymce'
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';



export default class Warranty {
  constructor() {
    tinymce.init({
      selector: 'textarea#editable-warranty',
      skin_url: '/css/skins/ui/oxide',
      theme_url: '/css/skins/content/default',
      plugins : 'advlist autolink link image lists charmap print preview',
      branding: false,
      height: "50vh"
    });
  }
}