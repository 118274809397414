<template>
    <div>
        <div class="milestone-desc-wrap">
          <transition-group name="slide-in">
              <span class="milestone-title" v-bind:key="content.title">{{ content.title }}</span>
              <span v-bind:key="content.start_date">{{ new Date(content.start_date).toLocaleDateString("en-US") }} - {{ new Date(content.end_date).toLocaleDateString("en-US") }}</span>
          </transition-group>
        </div>
      </div>
</template>
<script>
  export default {
    name: 'progressBarDescription',
    props: ['content']
  }
</script>