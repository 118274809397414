import Vue from 'vue';
import Component from "vue-class-component";
import { Milestone } from './schedule-script';

interface CalendarEvent {
  date: string
  time: string
  year: number
  month: number
  day: number
  hour: number
  minute: number
  weekday: number
  hasDay: boolean
  hasTime: boolean
  past: boolean
  present: boolean
  future: boolean
  timeToY: (time: string | number | { hour: number, minute: number }, clamp: boolean) => number
}

function defaultDate() {
  const today = new Date();
  return "" +
    today.getFullYear().toString() +
    "-" +
    (today.getMonth() + 1).toString() +
    "-" +
    today.getDate().toString();
}

function calendarView() {
  if (window.screen.width <= 768) {
    return "week"
  }  
  else {
    return "month"
  }
}

@Component({
  props: {
    events: {
      type: Array
    }
  }
})
export default class Calendar extends Vue {

  events: Milestone[];
  today = defaultDate();
  focus = defaultDate();
  type = calendarView();
  typeToLabel = {
    month: "Month",
    week: "Week",
    day: "Day",
    "4day": "4 Days"
  };

  start: CalendarEvent = null;
  end: CalendarEvent = null;
  selectedEvent: CalendarEvent | {} = {}
  selectedElement = null;
  selectedOpen = false;

  get title() {
    const { start, end } = this;

    if (!start || !end) {
      const date = new Date(this.today);
      const month = date.toLocaleString('default', { month: 'long' });
      return `${month} ${date.getFullYear()}`
    }

    const startMonth = this.monthFormatter()(start);
    const endMonth = this.monthFormatter()(end);
    const suffixMonth = startMonth === endMonth ? "" : endMonth;

    const startYear = start.year;
    const endYear = end.year;
    const suffixYear = startYear === endYear ? "" : endYear;

    const startDay = start.day + this.nth(start.day);
    const endDay = end.day + this.nth(end.day);

    switch (this.type) {
      case "month":
        return `${startMonth} ${startYear}`;
      case "week":
      case "4day":
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
      case "day":
        return `${startMonth} ${startDay} ${startYear}`;
    }
    return "";
  }

  monthFormatter() {
    return this.calendarInstance.getFormatter({
      timeZone: "UTC",
      month: "long"
    });
  }

  viewDay({ date }) {
    this.focus = date;
    this.type = "day";
  }

  getEventColor(event: Milestone) {
    return event.color;
  }

  setToday() {
    this.focus = this.today;
  }

  prev() {
    this.calendarInstance.prev();
  }

  next() {
    this.calendarInstance.next();
  }

  showEvent({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  updateRange({ start, end }) {
    // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
    this.start = start;
    this.end = end;
  }

  nth(d: number) {
    return d > 3 && d < 21
      ? "th"
      : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
  }

  private get calendarInstance(): Vue & {
    prev: () => void, next: () => void,
    getFormatter: (format: any) => any
  } {
    return this.$refs.calendar as Vue & { prev: () => void, next: () => void, getFormatter: (format: any) => any };
  }
}