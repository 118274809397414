import $ from "jquery";
export default class FileInput {
  constructor() {
    var input = $("input:file")[0];
    input.addEventListener( 'change', function( e ) {
      var fileName = document.getElementById('file-name-title')
      fileName.innerHTML = e.target.value.split("\\").pop()
      var button = input.previousElementSibling
      button.innerHTML = "1 File Selected"
      $(button).addClass("active")
    });
  }
}