import $ from "jquery";
export default class DueDateChecker {
  constructor() {
    $('#has-due-date').click( function() {
      $('#due-date').prop('disabled', !this.checked)
      $('#due-date').prop('required', this.checked)
      if (this.checked == false) {
        document.getElementById('due-date').value = ""
      }
    })
  }
}