import Vue from "vue";
import Component from "vue-class-component";
import VueResource from "vue-resource";
import Vuetify from "vuetify";
import ProgressBarDescription from "./ProgressBarDescription.vue";
import ProgressBarItem from "./ProgressBarItem.vue";
import ChecklistItem from "./ChecklistItem.vue";
import Calendar from "./Calendar.vue";
Vue.use(VueResource);
Vue.use(Vuetify);

interface MilestoneResponse extends VueResource.HttpResponse {
  data: MilestoneData[]
}

interface MilestoneData {
  title: string;
  start_date: string;
  end_date: string;
}

interface ProjectResponse extends VueResource.HttpResponse {
  data: { end_date: string };
}

export class Milestone {
  name: string;
  details: string;
  start: string;
  end: string;
  color: string;
}

@Component({
  components: {
    "progress-bar-description": ProgressBarDescription,
    "progress-bar-item": ProgressBarItem,
    "checklist-item": ChecklistItem,
    calendar: Calendar
  },
  props: {
    projectID: {
      type: Number,
      required: true
    }
  }
})
export default class HomepageModule extends Vue {
  projectID: number;
  milestones: MilestoneData[] = new Array<MilestoneData>();
  alphaMilestones: MilestoneData[] = new Array<MilestoneData>();
  calMilestones: Milestone[] = new Array<Milestone>();
  progressBarDesc = {
    title: "",
    start_date: "  ",
    end_date: "   "
  } as MilestoneData;

  initMilestones(instance: HomepageModule) {
    instance.$http.get(`/api/project/${this.projectID}/milestones`).then(
      (response: MilestoneResponse) => {
        instance.milestones = response.data;
        instance.alphaMilestones = instance.milestones.slice(0);
        instance.alphaMilestones.sort(instance.sortAlphaNum);
        instance.milestones.forEach((milestoneData: MilestoneData) => {
          const milestone = new Milestone();
          milestone.name = milestoneData.title;
          milestone.start = milestoneData.start_date.substr(0, 10);
          milestone.end = milestoneData.end_date.substr(0, 10);
          var color = "blue"
          if (milestoneData.type == "holiday") {
            color = "red";
          } else if (milestoneData.type == "inspection") {
            color = "orange"
          } else if (milestoneData.type == "closing") {
            color = "green"
          } else if (milestoneData.type == "document") {
            color = "pink"
          } else if (milestoneData.type == "default") {
            color = "blue"
          }
          milestone.color = color;
          instance.calMilestones.push(milestone);
        });
        instance.progressBarDesc = instance.milestones[0];
      },
      response => {
        console.warn("Error loading milestones: %o", response.text);
      }
    );
  }

  initCountdownClock(instance: HomepageModule) {
    instance.$http.get(`/api/project/${this.projectID}`).then(
      (response: ProjectResponse) => {
        let endDate = new Date(response.data.end_date).getTime()
        let now = new Date().getTime()
        let distance = endDate - now
        let days = Math.floor(distance / (1000 * 60 * 60 * 24))
        debugger
        console.log("eouoau")
        document.getElementById("days").innerHTML = parseInt(days)
        setTimeout(function() {
          document.getElementById("days").classList.add("show")
        }, 1000)
        if (distance < 0) {
          document.getElementById("days").innerHTML = "0"
        }
      },
      (response: ProjectResponse) => {
        console.warn("Error initializing countdown clock: %o", response.text)
      }
    );
  }

  newDescription(description: MilestoneData) {
    this.progressBarDesc = description;
  }

  sortAlphaNum(a: MilestoneData, b: MilestoneData) {
    return a.title.localeCompare(b.title, "en", { numeric: true });
  }

  mounted() {
    this.initMilestones(this);
    this.initCountdownClock(this);
  }
}