import $ from "jquery";
export default class Table {
  constructor() {
    $('ul.nav-tabs li a.nav-link').click( function() {
      var jj = $
      // Reset tabs
      $('ul.nav-tabs li').each( function() {
        let link = this.children[0]
        $(link).removeClass('active')
      })
      // Mark current tab active
      $(this).addClass('active')
      // Hide all tables
      let tables = $('.table-group').children()
      $(tables).each( function() { $(this).hide() })
      // Show correct table
      $(tables[this.dataset.tabNumber]).show()
    })
  }
}