import Homepage from './homepage.vue';
import Schedule from './schedule.vue';
import Warranty from './warranty';
import Dialog from './dialog';
import Table from './table';
import DueDateChecker from './due-date-checker';
import Scrapbook from './scrapbook';
import Menu from './menu';
import Modal from './modal';
import FileInput from './file-input';

export { Homepage as homepage }
export { Schedule as schedule }
export { Dialog as dialog }
export { Warranty as warranty }
export { Table as table }
export { DueDateChecker as dueDateChecker }
export { Scrapbook as scrapbook }
export { Menu as menu }
export { Modal as modal }
export { FileInput as fileInput }

import Vue from 'vue';
import Vuetify from 'vuetify';
const vuetifyOptions = { }
Vue.use(Vuetify)

export function constructSchedule(projectID: number, element: string) {
  new Schedule({el: element, vuetify: new Vuetify({}), propsData: {projectID: projectID}});
}

export function constructHomepage(projectID: number, element: string) {
  new Homepage({el: element, vuetify: new Vuetify({}), propsData: {projectID: projectID}});
}